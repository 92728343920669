.payment-options {
  display: flex;
  justify-content: center;
  gap: var(--horizontal-gap);
}

.payment-option {
  color: var(--navbar-color);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-option i {
  width: 72px;
  height: 48px;
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 28px;
  color: var(--navbar-color);

  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-selected {
  color: inherit;
}

.payment-selected i {
  border: 1px solid var(--accent-color);
  color: var(--accent-color-dark);
}
