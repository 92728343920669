.interactive-menu-item-popup-contents {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: var(--vertical-page-gap) var(--horizontal-menu-gap);
  width: calc(100vw - 64px);
  box-sizing: border-box;

  background-color: var(--main-color);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    "menu-name menu-price"
    "menu-description menu-description";
  align-items: center;
  align-items: flex-start;
}

.interactive-menu-item-popup-title {
  margin-bottom: 32px;

  font-size: 28px;
  line-height: 28px;

  grid-area: menu-name;
}

.interactive-menu-item-popup-price {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;

  grid-area: menu-price;
}

.interactive-menu-item-popup-description {
  grid-area: menu-description;
}

.interactive-menu-item-popup-shadow {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: var(--shadow-color);
}
