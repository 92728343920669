.menu {
  width: 100%;
}

.menu-loading {
  position: fixed;
  top: 32px;
  width: 100%;
  height: calc(100vh - 32px);

  background-color: var(--bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.menu-loading p {
  margin-bottom: 128px;
}
