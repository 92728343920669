:root {
  --horizontal-gap: 16px;
  --vertical-gap: 16px;
  --horizontal-menu-gap: 32px;
  --horizontal-page-gap: 32px;
  --vertical-page-gap: 48px;
  /* --icon-size: 16px; */

  --accent-color: #e64560;  /* HSV 350 70 90 */
  --accent-color-dark: #cc3d55;
  --main-color: #ffffff;
  --bg-color: #f6f6f6;
  --navbar-color: #eaeaea;
  --action-panel-color: #eaeaea;
  --subtitle-color: #666666;
  --shadow-color: rgba(0, 0, 0, 0.2);
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: var(--bg-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
