.bottom-nav {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 64px;

  background-color: var(--navbar-color);
  /* border-top: 1px solid var(--subtitle-color); */

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bottom-nav-item {
  color: var(--subtitle-color);
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-nav-item i {
  margin-bottom: 4px;

  font-size: 20px;
}

.bottom-nav-item p {
  font-size: 12px;
}
