.alerts {
  position: fixed;
  top: 32px;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 32px;

  display: flex;
  flex-direction: column;
  gap: var(--vertical-gap);
}

.alert {
  padding: 16px 16px;
  /* border-radius: 4px; */

  box-shadow: 0px 2px 12px var(--shadow-color);
  background-color: var(--bg-color);
  font-weight: 500;
}
