.app {
  min-height: calc(100vh - 32px - 96px);
  /* margin: 32px 0px; */
  padding: var(--vertical-gap) 0px;
  border-top: 32px solid var(--bg-color);
  border-bottom: 64px solid var(--bg-color);

  background-color: var(--bg-color);

  display: flex;
  flex-direction: column;
  gap: var(--vertical-gap);
}
