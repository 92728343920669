.interactive-menu-name {
  margin-bottom: 10px;

  font-size: 28px;
  line-height: 28px;
}

.interactive-menu-category {
  margin-top: 32px;
  margin-bottom: 10px;

  font-size: 18px;
  color: var(--accent-color-dark);
}
