.survey-contents {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: var(--vertical-page-gap) var(--horizontal-menu-gap);
  width: calc(100vw - 64px);
  box-sizing: border-box;

  background-color: var(--main-color);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
}

.survey-title {
  margin-bottom: 32px;

  font-size: 28px;
  line-height: 28px;
}

.survey-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.survey-button {
  flex-grow: 1;
  height: 40px;
  padding: 0px 16px;
  background-color: var(--navbar-color);
  /* border-radius: 4px; */

  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.survey-shadow {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: var(--shadow-color);
}
