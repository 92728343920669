.action-panel {
  position: fixed;
}

.action-panel-content {
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-bottom: 40px;

  background-color: var(--action-panel-color);
}

.action-panel-handle {
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.action-panel-items {
  padding: var(--vertical-gap) 24px;

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 18px;
  column-gap: 16px;
  justify-content: space-between;
}

.action-panel-shadow {
  position: fixed;
  top: 32px;
  width: 100vw;
  height: calc(100vh - 32px);
  background-color: var(--shadow-color);
}
