.pdf-menu {
  padding: var(--vertical-gap) var(--horizontal-gap);

  display: flex;
  flex-direction: column;
  gap: var(--vertical-gap);
}

.pdf-menu-page {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
