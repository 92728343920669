.order-page-title {
  margin-bottom: 32px;

  font-size: 28px;
  line-height: 28px;
}

.order-page-items {
  margin-bottom: 32px;
}

.order-page-item {
  margin-bottom: 10px;

  font-size: 15px;
  line-height: 20px;

  display: flex;
  justify-content: space-between;
}

.order-button {
  height: 40px;
  padding: 0px 16px;
  background-color: var(--navbar-color);
  /* border-radius: 4px; */

  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}
