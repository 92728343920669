.action-item {
  /* padding: 0px 16px; */

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 4px;
}

.action-button {
  width: 48px;
  height: 48px;
  border: 1px solid var(--accent-color-dark);
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 20px;
  color: var(--accent-color-dark);

  /* background-color: var(--accent-color); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-item p {
  color: var(--subtitle-color);

  max-width: 100%;
  text-align: center;
  word-wrap: break-word;
}
