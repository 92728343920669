.settings-panel {
  position: fixed;
}

.settings-panel-content {
  position: fixed;
  top: 32px;
  right: 0px;
  height: calc(100vh - 32px);
  width: min(80vh, 320px);
  padding: var(--vertical-gap) var(--vertical-gap);
  box-sizing: border-box;
  background-color: var(--bg-color);

  /* font-size: 24px; */
  display: flex;
  flex-direction: column;
  align-items:flex-end;
}

.settings-panel-content p {
  color: var(--subtitle-color);
}

.settings-panel-content hr {
  margin: var(--vertical-gap) 0px;
  border: none;
  border-top: 1px solid var(--subtitle-color);
}

.settings-panel-close {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  background-color: var(--navbar-color);
  border-radius: 50%;

  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-panel-shadow {
  position: fixed;
  top: 32px;
  right: 0px;
  height: calc(100vh - 32px);
  width: 100vw;
  background-color: var(--shadow-color);
}

.settings-panel-button {
  min-height: 40px;
  width: 100%;
  border: none;

  background-color: var(--navbar-color);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-panel-button a {
  color: inherit;
  text-decoration: none;
}
