.navbar {
  position: fixed;
  top: 0;
  width: calc(100% - 32px);
  height: 32px;
  padding: 0px var(--horizontal-gap);

  background-color: var(--navbar-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-title {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  transform: rotate(30deg);
}

.navbar h1 {
  font-size: 16px;
}
