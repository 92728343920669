.interactive-menu-item {
  margin-bottom: 10px;

  font-size: 15px;
  line-height: 20px;

  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    "menu-name menu-price menu-item-amount"
    "menu-description . .";
  align-items: center;
  align-items: flex-start;
}

.interactive-menu-item-name {
  font-weight: bold;

  grid-area: menu-name;
}

.interactive-menu-item-description {
  grid-area: menu-description; 
}

.interactive-menu-item-price {
  font-weight: bold;
  text-align: right;

  grid-area: menu-price;
}

.interactive-menu-item-amount {

  display: flex;
  gap: 8px;
  align-items: center;
  grid-area: menu-item-amount;
}

.interactive-menu-item-amount > div {
  height: 20px;
  width: 20px;
  /* border-radius: 2px; */

  background-color: var(--bg-color);
  font-size: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}
