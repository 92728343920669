.checkout-page {
  gap: 32px;
}

.checkout-page-title {
  font-size: 28px;
  line-height: 28px;
}

.checkout-buttons {
  display: flex;
  gap: 16px;
}

.checkout-button {
  flex-grow: 1;
  height: 40px;
  padding: 0px 16px;
  background-color: var(--navbar-color);
  /* border-radius: 4px; */

  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}
