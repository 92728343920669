.bill {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.bill-item {
  display: flex;
  justify-content: space-between;
}

.bill-button {
  height: 40px;
  border: none;
  background-color: var(--navbar-color);

  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
}
