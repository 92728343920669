.page {
  margin: 0px var(--horizontal-gap);
  padding: var(--vertical-page-gap) var(--horizontal-menu-gap);

  background-color: var(--main-color);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
}
