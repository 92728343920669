.logo {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 2px solid var(--accent-color);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-center {
  width: 24px;
  height: 24px;
  border-radius: 2px;

  background-color: var(--accent-color);
}

.logo-small {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 2px solid var(--accent-color);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-center-small {
  width: 8px;
  height: 8px;
  border-radius: 1px;

  background-color: var(--accent-color);
}
