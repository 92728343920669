.loading-screen {
  position: fixed;
  top: 32px;
  width: 100%;
  height: calc(100vh - 32px);

  background-color: var(--bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}